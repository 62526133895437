
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import EnumerationInfoPanel from './enumeration-info-panel.vue'
import EnumList from './data-list/index.vue'
import { getterCurEnumerationId } from '@/store'

@Component({
  name: 'EnumerationView',
  components: { EnumerationInfoPanel, EnumList }
})
export default class EnumerationInfoView extends Vue {
  // 当前的Enumerationid
  get currentEnumId () {
    return getterCurEnumerationId()
  }
}
