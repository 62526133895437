/**
 * Enumeration配置模块
 */
import DefaultApiService from '@/libs/api-request'
import { EnumerationItem } from './enum-item'

/**
 * Enumeration配置服务请求api接口
 */
class EnumerationItemConfigApi extends DefaultApiService<EnumerationItem> {
  /**
     * 启用或禁用enumItem
     * @param enumItem
     * @returns
     */
  enableOrDisableEnumerationItem (enumItem: EnumerationItem) {
    return this.request(`${enumItem.disable ? 'enable' : 'disable'}/${enumItem.id}`, null, 'PUT')
  }
}

export default new EnumerationItemConfigApi('/api/enumerationItem')
