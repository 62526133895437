
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'

/**
 * 团队信息，在系统右上角显示当前团队的基本信息
 */
@Component({
  name: 'expendSearchPanel'
})
export default class ExpendSearchPanel extends Vue {
    expanding=false

    /**
     * 加载状态
     */
    @Prop({
      type: Boolean,
      required: true,
      default: false
    })
    loading?: boolean

    /**
     * 是否展示展开按钮
     */
    @Prop({
      type: Boolean,
      required: false,
      default: true
    })
    showExpandingSearch?: boolean

    expandingSearch () {
      this.expanding = !this.expanding
      if (!this.expanding) this.$emit('on-shrink-seachbar')
    }

    /**
     * 执行搜索
     */
    doSearch () {
      this.$emit('on-refresh')
    }
}
