
import { Vue, Component, Watch } from 'vue-property-decorator'
import Modals from './modal/modals'
import { updateCurEnumerationIdAndInfo, getterCurEnumerationId, getterUserInfo } from '@/store'
import { EnumerationConfig } from '@/api/project/enummanager/enum-config/enum-config'
import EnumerationConfigApi from '@/api/project/enummanager/enum-config/enum-config-api'

@Component({
  name: 'EnumerationPanel',
  components: {}
})
export default class EnumerationInfoPanel extends Vue {
  /// 当前Enumerationid
  get currentEnumerationId () {
    return getterCurEnumerationId()
  }

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const userInfo = getterUserInfo()
    return userInfo?.updateDataModel
  }

  isShowSpin = false

  enumerationConfig: EnumerationConfig = {}

  rules = {
    title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
    name: [
      { required: true, message: '枚举名称不能为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与数字的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    packageName: [
      { required: true, message: '包名不能为空', trigger: 'blur' },
      {
        message: '只能字母开头，包含数字、小写字母、下划线并用.分隔',
        pattern: '^[a-z][a-z0-9_]*(.[a-z0-9_]+)+[0-9a-z_]$',
        trigger: 'blur'
      }
    ]
  }

  created () {
    this.loadDataFromServer()
  }

  @Watch('currentEnumerationId')
  onCurrentEnumerationId () {
    this.loadDataFromServer()
  }

  // 从服务器加载数据
  loadDataFromServer () {
    if (this.currentEnumerationId !== 0 && !this.currentEnumerationId) {
      this.enumerationConfig = {}
      return
    }
    this.isShowSpin = true
    EnumerationConfigApi.getItemById(this.currentEnumerationId)
      .then(resp => {
        this.enumerationConfig = resp.data!
      })
      .catch(err => {
        this.$Message.error('查询失败，原因：' + err.message)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }

  onClickUpdateEnumerationButton () {
    Modals.showEnumerationConfigUpdaterModal(this.enumerationConfig, data => {
      return new Promise<void>((resolve, reject) => {
        EnumerationConfigApi.updateItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            // 通知修改了control的数据
            updateCurEnumerationIdAndInfo(res.data!.id!, {
              name: res.data!.name!,
              title: res.data!.title!
            })
            this.loadDataFromServer()
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }
}
