
import { Component, Prop, Watch } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import BaseTablePanel from '@/frame/share/table-panel'
import tableConfig from './index-table.config'
import {
  PagerQueryBean,
  PageListData,
  PageDataApiResult
} from '@/libs/http-request'
import { PageInfo } from '@/libs/api-request'
import _ from 'lodash'
import { EnumerationItem } from '@/api/project/enummanager/enum-item/enum-item'
import EnumerationItemApi from '@/api/project/enummanager/enum-item/enum-item-api'
import Modals from '../modal/modals'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}

@Component({
  name: 'dataList',
  components: { ExpendSearchPanel, TablePanel }
})
export default class DataList extends BaseTablePanel<EnumerationItem> {
  // 当前状态
  loading = false;
  /// 表格定义
  tableConfig = tableConfig;

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);
  /// 选中的列
  selectItems: Array<any> = [];

  /// 枚举ID
  @Prop({
    type: Number,
    default: 0,
    required: true
  })
  currentEnumId!: number;

  /**
   * 页面数据
   */
  pageData: PageListData<EnumerationItem> = {
    items: [],
    total: 0,
    pageSize: 10
  };

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  @Watch('currentEnumId')
  onEnumIdChange () {
    this.doPullDataList()
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)
    })
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(
      code,
      (this.$refs.tablePanel as any).getTableObject()
    )
  }

  /**
   * 复选框点击事件
   */
  protected onSelectionChange (datas: Array<any>) {
    this.selectItems = datas
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<EnumerationItem>> {
    // 添加当前的枚举id
    pagerQueryBean.enumId = this.currentEnumId
    return EnumerationItemApi.query(pagerQueryBean)
  }

  /**
   * 新增按钮被点击
   */
  protected onCreateClick () {
    Modals.showEnumerationItemConfigCreatorModal(async data => {
      data.enumId = this.currentEnumId
      await EnumerationItemApi.insertItem(data)
        .then(res => {
          this.$Message.success('操作成功')
          this.doPullDataList()
        })
        .catch(err => {
          this.$Message.error({
            content: err.message
          })
        })
    })
  }

  /**
   * 批量删除按钮被点击
   */
  protected onDeleteSelectClick () {
    if (_.isEmpty(this.selectItems)) return
    this.$Modal.confirm({
      title: '是否删除？',
      content: `是否删除 ${this.selectItems.map(e => e.name)} 枚举项？`,
      onOk: () => {
        EnumerationItemApi.deleteItemByIds(this.selectItems.map(e => e.id))
          .then(resp => {
            this.$Message.info('操作成功')
            this.selectItems = []
            this.doPullDataList()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  /**
   * 单项编辑操作
   */
  onEditorClick (row: EnumerationItem) {
    Modals.showEnumerationItemConfigUpdaterModal(row, async data => {
      await EnumerationItemApi.updateItem(data)
        .then(res => {
          this.$Message.success('操作成功')
          this.doPullDataList()
        })
        .catch(err => {
          this.$Message.error({
            content: err.message
          })
        })
    })
  }

  /**
   * 单项删除操作
   */
  onDeleteClick (enumItemId: number) {
    this.$Modal.confirm({
      title: '是否删除？',
      content: '是否删除该枚举项？',
      onOk: () => {
        EnumerationItemApi.deleteItemByIds([enumItemId])
          .then(resp => {
            this.$Message.info('操作成功')
            this.doPullDataList()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  /**
   * 单项启用、禁用翻转操作
   */
  onToggleEnableClick (enumItem: EnumerationItem) {
    const action = enumItem.disable ? '启用' : '禁用'
    this.$Modal.confirm({
      title: `是否${action}？`,
      content: `是否${action}该枚举项？`,
      onOk: () => {
        EnumerationItemApi.enableOrDisableEnumerationItem(enumItem)
          .then(resp => {
            this.$Message.info('操作成功')
            this.doPullDataList()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }
}
